


























































































































import Vue, { PropType } from 'vue';
import KeyPresses from './KeyPresses.vue';
import _fromPairs from 'lodash/fromPairs';
import _findKey from 'lodash/findKey';
import {
  DEFAULT_CONFIG,
  TextAnnotatorParameters,
  TextAnnotationLabel,
  SaveTextAnnotationLabels,
  TextAnnotationFeature
} from './utils';

// TODO Do these need to be configurable and have multiple values?
const CERTAINTY_LABEL_TO_BOOLEAN = {
  Sure: true,
  Unsure: false
};

const CERTAINTY_DEFAULT = true;

export default Vue.extend({
  name: 'TextClassificationView',
  components: {
    KeyPresses
  },
  props: {
    assetIndex: {
      type: Number,
      required: true
    },
    canAnnotate: {
      type: Boolean,
      required: true
    },
    numberOfAssets: {
      type: Number,
      required: true
    },
    activeText: {
      type: String,
      required: true
    },
    predictions: {
      required: true,
      type: Array as PropType<TextAnnotationLabel[]>
    },
    previousAnnotations: {
      required: true,
      type: Array as PropType<TextAnnotationLabel[]>
    },
    save: {
      required: true,
      type: Function as PropType<SaveTextAnnotationLabels>
    },
    next: {
      required: true,
      type: Function as PropType<() => void>
    },
    prev: {
      required: true,
      type: Function as PropType<() => void>
    },
    nextNotAnnotated: {
      required: true,
      type: Function as PropType<(index: number) => void>
    },
    nextNotAnnotatedAssetIndex: {
      required: true,
      type: Number
    },
    previousNotAnnotatedAssetIndex: {
      required: true,
      type: Number
    },
    config: {
      required: true,
      type: Object as PropType<TextAnnotatorParameters>,
      validator: function(value) {
        // The config must have the same keys as DEFAULT_CONFIG
        return Object.keys(DEFAULT_CONFIG).every(defaultConfigKey =>
          Object.keys(value).includes(defaultConfigKey)
        );
      }
    }
  },
  mounted() {
    this.focusFirstSelect();
  },
  data() {
    return {
      tab: 0,
      showInputInvalid: false,
      showNothingChanged: false,
      singleClassLabel: null,
      selected: [],
      certainties: {},
      labelChoiceKeys: ['a', 's', 'd', 'f', 'e', 'g', 'h', 'j', 'k', 'l'],
      fontSizeRem: 1,
      selectedLabelByFeature: {} as Record<string, string>,
      certaintyTickedByFeature: {} as Record<string, boolean>,
      rules: [(v: string) => !!v && v.length > 0],
      inputValid: undefined as boolean | undefined
    };
  },
  created(): void {
    this.initializeFromExistingLabels();
  },
  computed: {
    title(): string {
      return `Text classification (${this.assetIndex + 1}/${
        this.numberOfAssets
      })`;
    },
    availableFeatures(): TextAnnotationFeature[] {
      return this.config.features;
    },
    selectedCertaintyByFeature(): Record<string, string> {
      return _fromPairs(
        this.availableFeatures.map(feature => {
          const asBoolean = this.certaintyTickedByFeature[feature.name];
          const certaintyAsLabel = this.certaintyLabelFromBoolean(asBoolean);
          return [feature.name, certaintyAsLabel];
        })
      );
    },
    hasPredictions(): boolean {
      return !!this.predictions && this.predictions.length > 0;
    },
    hasPreviousAnnotations(): boolean {
      return !!this.previousAnnotations && this.previousAnnotations.length > 0;
    }
  },
  methods: {
    certaintyLabelFromBoolean(bool: boolean): string {
      const certaintyAsLabel = _findKey(
        CERTAINTY_LABEL_TO_BOOLEAN,
        val => val == bool
      );
      return certaintyAsLabel;
    },
    itemsByFeature(feature: TextAnnotationFeature): string[] {
      const classes = feature.classes;
      return classes.map(cls => cls.name);
    },
    handleKeyPress(e) {
      // Non-numeric actions
      const keyToAction = {
        '.': this.onClickNext,
        m: this.onClickPrevNotAnnotated,
        ',': this.onClickPrev,
        '-': this.onClickNextNotAnnotated
      };
      const keyPressed = e.shiftKey ? `Shift+${e.key}` : e.key;
      const action = keyToAction[keyPressed];

      action?.();
    },
    async onClickNext() {
      await this.saveIfShould();
      this.next();
      this.scrollToTop();
    },
    async onClickNextNotAnnotated() {
      if (this.nextNotAnnotatedAssetIndex === -1) {
        return;
      }
      await this.saveIfShould();
      this.nextNotAnnotated(this.nextNotAnnotatedAssetIndex);
      this.scrollToTop();
    },
    async onClickPrev() {
      await this.saveIfShould();
      this.prev();
      this.scrollToTop();
    },
    async onClickPrevNotAnnotated() {
      if (this.previousNotAnnotatedAssetIndex === -1) {
        return;
      }
      await this.saveIfShould();
      this.nextNotAnnotated(this.previousNotAnnotatedAssetIndex);
      this.scrollToTop();
    },
    onClickSkip() {
      this.next();
      this.scrollToTop();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    focusFirstSelect() {
      const keys = Object.keys(this.$refs).includes('select')
        ? Object.keys(this.$refs.select)
        : [];
      if (keys.length > 0) {
        setTimeout(() => this.$refs.select[0].focus(), 300);
      }
    },
    async saveIfShould() {
      if (!this.canAnnotate) {
        return;
      }

      if (!this.inputValid) {
        this.showInputInvalid = true;
        return;
      }

      const labelsToSave: TextAnnotationLabel[] = this.availableFeatures.map(
        availableFeature => {
          const certainty = {
            certainty: this.certaintyLabelFromBoolean(
              this.certaintyTickedByFeature[availableFeature.name]
            )
          };

          return {
            feature: availableFeature.name,
            value: this.selectedLabelByFeature[availableFeature.name],
            ...certainty
          };
        }
      );

      await this.save(labelsToSave);
    },
    initializeFromExistingLabels() {
      console.log(`Initializing from labels`, this.previousAnnotations);

      const features = this.availableFeatures;

      // Reset selections to defaults
      this.selectedLabelByFeature = _fromPairs(
        features.map(feature => [
          feature.name,
          feature.classes.length > 0 ? feature.classes[0].name : undefined
        ])
      );

      this.certaintyTickedByFeature = _fromPairs(
        features.map(feature => [feature.name, CERTAINTY_DEFAULT])
      );

      if (this.hasPreviousAnnotations) {
        // Fill from previous labels
        for (const prev of this.previousAnnotations) {
          const featureName = prev.feature;
          this.selectedLabelByFeature[featureName] = prev.value;
          this.certaintyTickedByFeature[featureName] = prev.certainty
            ? CERTAINTY_LABEL_TO_BOOLEAN[prev.certainty]
            : CERTAINTY_DEFAULT;
        }
      } else if (this.hasPredictions) {
        // Fill with pre-annotated labels
        for (const pred of this.predictions) {
          const featureName = pred.feature;
          this.selectedLabelByFeature[featureName] = pred.value;
          this.certaintyTickedByFeature[featureName] = pred.certainty
            ? CERTAINTY_LABEL_TO_BOOLEAN[pred.certainty]
            : CERTAINTY_DEFAULT;
        }
      }
    }
  },
  filters: {
    addLineBreaks: function(val) {
      return val.replace(/<br \/>/g, '\n').replace(/\\n/g, '\n');
    }
  },
  watch: {
    assetIndex: function() {
      this.initializeFromExistingLabels();
      this.focusFirstSelect();
    }
  }
});
