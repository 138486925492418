











































































import Vue from 'vue';
import AnnotationView from './TextClassificationView.vue';
import _isEqual from 'lodash/isEqual';
import _indexOf from 'lodash/indexOf';
import _findIndex from 'lodash/findIndex';
import _findLastIndex from 'lodash/findLastIndex';
import _fromPairs from 'lodash/fromPairs';
import { mapGetters } from 'vuex';
import {
  resolveParameters,
  TextAnnotatorParameters,
  TextAnnotationLabel,
  findTextMediaSlug
} from './utils';
import _omit from 'lodash/omit';
import { AnnotatedAsset, Asset, Label } from '@/types';
import LoadingModal from '../LoadingModal.vue';
import { ANNOTATION_UI_PROPS } from '@/components/annotations/props';

const isTextAnnotationLabel = (label: Label): label is TextAnnotationLabel => {
  return !!label.feature && !!label.value;
};

export default Vue.extend({
  name: 'TextClassificationController',
  components: {
    AnnotationView,
    LoadingModal
  },
  props: ANNOTATION_UI_PROPS,
  created() {
    this.initialize();
  },
  data: () => ({
    activeText: '',
    activeAssetAnnotations: null as TextAnnotationLabel[] | null,
    activeAssetPredictions: null as TextAnnotationLabel[] | null,
    saving: false,
    errorLoading: null as string | null,
    errorSaving: null as string | null,
    loading: false,
    showSuccess: false,
    showNothingChanged: false
  }),
  computed: {
    ...mapGetters('auth', ['currentUser']),
    progressPercentage(): number {
      return (this.status.annotations * 100.0) / this.status.assets;
    },
    config(): TextAnnotatorParameters {
      const parameters = this.annotationConfiguration?.parameters || {};
      return resolveParameters(parameters);
    },
    activeAssetIndex(): number {
      return _indexOf(this.assets, this.activeAsset);
    },
    nextNotAnnotatedAssetIndex(): number {
      return _findIndex(
        this.assets,
        asset => !this.assetIdToAnnotations[asset.id],
        this.activeAssetIndex + 1
      );
    },
    previousNotAnnotatedAssetIndex(): number {
      return _findLastIndex(
        this.assets.slice(0, this.activeAssetIndex),
        asset => !this.assetIdToAnnotations[asset.id]
      );
    },
    assetIdToAnnotations(): Record<string, AnnotatedAsset> {
      return _fromPairs(this.annotations.map(ann => [ann.asset.id, ann]));
    },
    canAnnotate(): boolean {
      /**
       * Allow also non-assignee users to annotate until we have
       * proper review support. Backend still checks for access
       * permissions!
       */
      return true;
      /* return (
        this.task.annotator && this.task.annotator.id === this.currentUser.id
      ); */
    }
  },
  methods: {
    initialize() {
      this.setActiveData();
    },
    async annotationsFor(
      asset: Asset
    ): Promise<TextAnnotationLabel[] | undefined> {
      console.log(`Fetching annotations for asset ${asset.id}`);
      const annotation = await this.$api.tasks.getAnnotationForAsset({
        taskId: this.task.id,
        assetId: asset.id
      });

      if (!annotation) {
        return [];
      }

      // Discard old format
      return annotation.labels
        .map(label => (isTextAnnotationLabel(label) ? label : undefined))
        .filter(label => !!label);
    },
    async predictionsFor(
      asset: Asset
    ): Promise<TextAnnotationLabel[] | undefined> {
      console.log(`Fetching prediction for asset ${asset.id}`);
      const prediction = await this.$api.tasks.getPreAnnotationForAsset({
        taskId: this.task.id,
        assetId: asset.id
      });

      if (!prediction) {
        return [];
      }

      // Discard old format
      return prediction.labels
        .map(label => (isTextAnnotationLabel(label) ? label : undefined))
        .filter(label => !!label);
    },
    nextAsset(): void {
      const index = this.activeAssetIndex;
      const newIndex = index + 1;
      if (newIndex >= this.assets.length) {
        this.onFinishAnnotating();
        return;
      }
      this.$emit('update:activeAsset', this.assets[newIndex]);
    },
    previousAsset(): void {
      const index = this.activeAssetIndex;
      const newIndex = index - 1;
      if (newIndex < 0) {
        return;
      }
      this.$emit('update:activeAsset', this.assets[newIndex]);
    },
    async resolveActiveText(): Promise<string> {
      const textKey = this.config.textAttribute;
      const attributes = this.activeAsset.attributes;
      const medias = this.activeAsset.media;

      const maybeTextMediaSlug = Object.keys(medias).includes(textKey)
        ? textKey
        : findTextMediaSlug(medias);

      if (maybeTextMediaSlug) {
        const media = medias[maybeTextMediaSlug];
        const mediaSlug = maybeTextMediaSlug;
        const mediaType = media.media_type;

        console.log(`Fetching media: ${mediaSlug} of type ${mediaType}`);

        const textMedia = await this.fetchMedia({
          assetId: this.activeAsset.id,
          mediaSlug,
          mediaType
        });

        return new TextDecoder().decode(textMedia);
      } else if (Object.keys(attributes).includes(textKey)) {
        return attributes[textKey];
      }
      throw new Error(
        `Could not resolve text from asset: ${JSON.stringify(this.activeAsset)}`
      );
    },
    async setActiveData(): Promise<void> {
      this.loading = true;
      this.errorLoading = null;

      this.activeText = '';
      this.activeAssetAnnotations = null;
      this.activeAssetPredictions = null;

      try {
        this.activeText = await this.resolveActiveText();

        this.activeAssetAnnotations = await this.annotationsFor(
          this.activeAsset
        );
        this.activeAssetPredictions = await this.predictionsFor(
          this.activeAsset
        );
      } catch (err) {
        this.errorLoading = err;
      } finally {
        this.loading = false;
      }
    },
    onNext(): void {
      this.nextAsset();
    },
    onPrev(): void {
      this.previousAsset();
    },
    onNextNotAnnotated(index: number): void {
      if (index === -1) {
        return;
      }
      this.$emit('update:activeAsset', this.assets[index]);
    },
    async saveAnnotations(labels: TextAnnotationLabel[]): Promise<void> {
      const previousAnnotations = this.activeAssetAnnotations;
      const previousAnnotationsWithoutTraceIds = previousAnnotations.map(ann =>
        _omit(ann, 'trace_id')
      );

      if (_isEqual(labels, previousAnnotationsWithoutTraceIds)) {
        console.log('Skipping saving as nothing changed');
        this.showNothingChanged = true;
        return;
      }

      console.log('Saving new labels', labels);

      this.saving = true;
      this.errorSaving = null;
      try {
        await this.onAnnotateAsset({
          assetId: this.activeAsset.id,
          labels: labels
        });
        this.showSuccess = true;
      } catch (err) {
        this.errorSaving = err;
        throw err;
      } finally {
        this.saving = false;
      }
    }
  },
  watch: {
    activeAsset: function() {
      this.initialize();
    }
  }
});
