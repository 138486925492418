<template>
  <div></div>
</template>

<script>
export default {
  created() {
    this.handler = e => {
      this.$emit('keyup', e);
    };
    window.addEventListener('keyup', this.handler);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.handler);
  }
};
</script>

<style scoped>
div {
  display: none;
}
</style>
